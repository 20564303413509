import React, { useRef, useState } from "react";
import * as styles from "./ContactUsPage.module.scss";
import EventTrackerService from "../../services/EventTrackerService";
import Captcha, { ICaptchaHandle } from "../../components/Shared/Captcha";
import { useForm } from "react-hook-form";
import { graphql, navigate } from "gatsby";
import ContactUsService, { ContactUsRequest } from "../../services/formRequestServices/ContactUsService";
import { IResponse } from "../../services/apiServices/ApiBaseService";
import Spinner from "../../components/Shared/Spinner";
import { SiteMetadataHead } from "../../components/Shared/SiteMetadataHead";

interface ContactUsProps {
    data: GatsbyTypes.ContactUsQuery;
    location: Location;
}

const ContactUsPageTemplate = (props: ContactUsProps) => {
    const [spinner, setSpinner] = useState<boolean>(false);
    const [showerror, setError] = useState<IResponse<any> | null>(null);
    const [formData, setFormData] = useState<{ [name: string]: string | any }>({});
    const captchaRef = useRef<ICaptchaHandle>(null);
    const {
        register,
        reset,
        formState: { errors },
        handleSubmit,
        clearErrors,
    } = useForm({ reValidateMode: "onBlur" });

    // page data
    const { strapiContactUsPage } = props.data;
    const { hero, contactUsInfo, contactUsForm } = strapiContactUsPage as GatsbyTypes.StrapiContactUsPage;

    function handleValueChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (showerror) setError(null);
        clearErrors(event.target.name);
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    async function resetForm(): Promise<void> {
        setFormData({ nda: false });
        reset({});
        captchaRef?.current?.reset();
    }

    async function onSubmit(): Promise<void> {
        setSpinner(true);
        EventTrackerService.formSubmit("Value analysis form", true);

        try {
            const response = await ContactUsService.submitForm(formData as unknown as ContactUsRequest);
            if (response.httpStatusCode !== 200) {
                setError(response);
                setSpinner(false);
                return console.error(response);
            }
            await resetForm();
            setSpinner(false);
            navigate("/thank-you-contact");
        } catch (error) {
            setError({
                success: false,
                message: "We have an unexpected error",
                httpStatusCode: 500,
                response: "",
            });
            setSpinner(false);
            return console.error(error);
        }
    }

    return (
        <>
            <section key={"hero"} className={styles.heroWrapper}>
                {hero && <h1 className="font-serif">{hero.heading}</h1>}
            </section>

            <div className={styles.contactWrapper}>
                <div className={`${styles.inner} mainBox`}>
                    <div className={styles.textBlock}>
                        <h2>{contactUsInfo.title}</h2>
                        {<div dangerouslySetInnerHTML={{ __html: contactUsInfo.paragraphHTML || contactUsInfo.paragraph }} />}
                    </div>
                    <div className={styles.formBlock}>
                        {spinner ? (
                            <Spinner />
                        ) : (
                            <>
                                {showerror && (
                                    <div>
                                        <p role="alert">Sorry, we have a problem:</p>
                                        <p role="alert">
                                            {showerror.response.name}, {showerror.response.message}. Check your data and try again{" "}
                                        </p>
                                    </div>
                                )}
                                <form name="contactUs" onSubmit={handleSubmit(onSubmit)} noValidate>
                                    <div>
                                        <label htmlFor="fullName">{contactUsForm.fullName.label} *</label>
                                        <input
                                            {...register("fullName", {
                                                required: { value: true, message: "This field is required" },
                                            })}
                                            className="formControl isOutline"
                                            placeholder={contactUsForm.fullName.placeholder ?? ""}
                                            type="text"
                                            id="fullName"
                                            name="fullName"
                                            maxLength={100}
                                            onChange={handleValueChange}
                                            value={formData.fullName ?? ""}
                                            aria-invalid={errors.fullName ? "true" : "false"}
                                        />
                                        {errors.fullName?.type === "required" && <p role="alert">{(errors.fullName.message as string) ?? ""}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="email">{contactUsForm.email.label} *</label>
                                        <input
                                            {...register("email", {
                                                required: { value: true, message: "This field is required" },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email format",
                                                },
                                            })}
                                            className="formControl isOutline"
                                            placeholder={contactUsForm.email.placeholder ?? ""}
                                            type="email"
                                            id="email"
                                            name="email"
                                            maxLength={100}
                                            onChange={handleValueChange}
                                            value={formData.email ?? ""}
                                            aria-invalid={errors.email ? "true" : "false"}
                                        />
                                        {(errors.email?.type === "required" || errors.email?.type === "pattern") && (
                                            <p role="alert">{(errors.email.message as string) ?? ""}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="company">{contactUsForm.company.label} *</label>
                                        <input
                                            {...register("company", { required: { value: true, message: "This field is required" } })}
                                            className="formControl isOutline"
                                            placeholder={contactUsForm.company.placeholder ?? ""}
                                            type="text"
                                            id="company"
                                            name="company"
                                            maxLength={100}
                                            onChange={handleValueChange}
                                            value={formData.company ?? ""}
                                            aria-invalid={errors.company ? "true" : "false"}
                                        />
                                        {errors.company?.type === "required" && <p role="alert">{(errors.company.message as string) ?? ""}</p>}
                                    </div>
                                    {/*
                                    regex matches
                                    xxx-xxx-xxxx
                                    (xxx) xxx-xxxx
                                    xxx xxx xxxx
                                    xxx.xxx.xxxx
                                    +xx (xxx) xxx-xxxx
                                    */}
                                    <div>
                                        <label htmlFor="phoneNumber">{contactUsForm.phoneNumber.label} *</label>
                                        <input
                                            {...register("phoneNumber", {
                                                required: { value: true, message: "This field is required" },
                                                pattern: {
                                                    value: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                                    message: "Invalid phone number",
                                                },
                                            })}
                                            className="formControl isOutline"
                                            placeholder={contactUsForm.phoneNumber.placeholder ?? ""}
                                            type="text"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            maxLength={13}
                                            onChange={handleValueChange}
                                            value={formData.phoneNumber ?? ""}
                                            aria-invalid={errors.phoneNumber ? "true" : "false"}
                                            onKeyPress={event => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors.phoneNumber?.message && <p role="alert">{(errors.phoneNumber.message as string) ?? ""}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="howCanWeHelp">{contactUsForm.howCanWeHelp.label} *</label>
                                        <textarea
                                            {...register("howCanWeHelp", { required: { value: true, message: "This field is required" } })}
                                            className="formControl isOutline"
                                            rows={4}
                                            placeholder={contactUsForm.howCanWeHelp.placeholder ?? ""}
                                            id="howCanWeHelp"
                                            name="howCanWeHelp"
                                            maxLength={1000}
                                            onChange={handleValueChange}
                                            value={formData.howCanWeHelp ?? ""}
                                            aria-invalid={errors.howCanWeHelp ? "true" : "false"}
                                        />
                                        {errors.howCanWeHelp?.message && <p role="alert">{(errors.howCanWeHelp.message as string) ?? ""}</p>}
                                    </div>
                                    <Captcha onVerified={token => setFormData({ ...formData, token })} ref={captchaRef} />
                                    <div className={styles.buttonWrapper}>
                                        <button className="button" type="submit" disabled={!formData?.token}>
                                            {contactUsForm.formActions.submitText}
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUsPageTemplate;

export const query = graphql`
    query ContactUs {
        strapiContactUsPage {
            hero {
                heading
            }
            contactUsForm {
                company {
                    label
                    placeholder
                }
                email {
                    label
                    placeholder
                }
                formActions {
                    submitLink
                    submitText
                }
                fullName {
                    label
                    placeholder
                }
                howCanWeHelp {
                    label
                    placeholder
                }
                phoneNumber {
                    label
                    placeholder
                }
            }
            contactUsInfo {
                paragraphHTML
                paragraph
                title
            }
        }
    }
`;

export function Head({ pageContext }: any) {
    const { seo } = pageContext;

    return (
        <SiteMetadataHead>
            <title id="site-title">{seo.title}</title>
            <meta id="desc" name="description" content={seo.description} />
            <meta id="tw-card" name="twitter:card" content={seo.twitter.card} />
            <meta id="tw-site" name="twitter:site" content={seo.twitter.site} />
            <meta id="tw-creator" name="twitter:creator" content={seo.twitter.creator} />
            <meta id="tw-title" name="twitter:title" content={seo.twitter.title} />
            <meta id="tw-desc" name="twitter:description" content={seo.twitter.description} />
            <meta id="tw-img" name="twitter:image" content={seo.twitter.image.url} />
            <meta id="fb-type" property="og:type" content={seo.facebook.type} />
            <meta id="fb-title" property="og:title" content={seo.facebook.title} />
            <meta id="fb-desc" property="og:description" content={seo.facebook.description} />
            <meta id="fb-url" property="og:url" content={seo.facebook.url} />
            <meta id="fb-img" property="og:image" content={seo.facebook.image.url} />
        </SiteMetadataHead>
    );
}
