import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";

export interface ContactUsRequest {
    fullName: string,
    email: string,
    company: string,
    phoneNumber: number,
    howCanWeHelp: string,
    token: string,
}
class ContactUsService extends ApiBaseService {
    public static async submitForm(request: ContactUsRequest): Promise<IResponse<any>> {
        const response = await this.securedPost<any>(`contact-requests`, { data: request });
        return response;
    }
}

export default ContactUsService;